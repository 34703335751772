import React from 'react';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';

import {
    titleText,
    separator,
    contentText,
    leadText,
    customTitle,
    subtitle,
    mainImg,
    metadata,
    posts,
    podcastButton,
    postWrapper,
    products,
    spotify,
    spotifyBanner,
    spotifyIcon,
    productsList,
} from './post.module.scss';
import FaqEmotics from '../assets/images/svg/faq-emotics.svg';
import EmoticsRelatedProd from '../assets/images/svg/emotics-related-products.svg';
import Spotify from '../assets/images/svg/logo_spotify.svg';
import useMediaWithSeo from '../hooks/use-media-with-seo';
import getNodes from '../utills/get-nodes';

import SEO from '../components/seo';
import Main from '../layouts/main';
import Title from '../components/atoms/title';
import TagList from '../components/molecules/tag-list';
import PostMetadata from '../components/atoms/post-metadata';
import RelatedList from '../components/organisms/related-list';
import PostPreview from '../components/molecules/post-preview';
import Banner from '../components/molecules/banner';
import Button from '../components/atoms/button';
import Separator from '../components/atoms/separator';
import SubTitle from '../components/atoms/subtitle';
import LinkPass from '../components/atoms/link-pass';
import ProductCard from '../components/molecules/product-card/product-card';
import List from '../components/molecules/list/list';
import Markdown from '../components/hoc/markdown';
import RatioImage from '../components/atoms/ratio-image';

const Post = ({ data }) => {
    const {
        allPost,
        allProduct,
        post: {
            title,
            slug,
            lead,
            content,
            publishedAt,
            updatedAt,
            author,
            tags,
            podcastListUri,
            podcastUri,
            media,
            meta,
        },
        site: { siteMetadata },
    } = data;

    const relatedPosts = getNodes(allPost);
    const relatedProducts = getNodes(allProduct);
    const cartLoading = useSelector((state) => state.ui.loaders.cart);

    const { url: mainImageUrl, alt: mainImageAlt, ogImageUrl } = useMediaWithSeo(media);

    let contentWithBreakLines = content?.split('{{').join('\n\n{{');
    contentWithBreakLines = contentWithBreakLines?.split('}}').join('}}\n\n');

    return (
        <>
            <SEO
                title={title}
                description={lead}
                image={ogImageUrl}
                replaceMeta={meta}
                structuredData={{
                    '@type': 'Article',
                    name: `${title} | ${siteMetadata.title}`,
                    headline: title,
                    image: mainImageUrl,
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': slug,
                    },
                    author: {
                        '@type': 'Person',
                        name: author ? `${author.firstName} ${author.lastName}` : '',
                    },
                    datePublished: publishedAt,
                    dateModified: updatedAt,
                    publisher: {
                        '@type': 'Organization',
                        url: data.site.siteMetadata.url,
                        logo: `${data.site.siteMetadata.url}${data.site.siteMetadata.image}`,
                        name: data.site.siteMetadata.title,
                    },
                }}
            />
            <Main className={postWrapper}>
                <section className={metadata}>
                    <PostMetadata author={author} publishedAt={publishedAt} />
                    <TagList tags={tags} />
                </section>
                <section className={titleText}>
                    <Title Tag="h1" className={customTitle}>
                        {title}
                    </Title>
                </section>
                <section className={leadText}>
                    <Markdown>{lead}</Markdown>
                </section>
                <section className={mainImg}>
                    <RatioImage src={mainImageUrl} alt={mainImageAlt} loading="eager" />
                </section>
                <section className={contentText}>
                    <Markdown className={'ck-content'}>{contentWithBreakLines}</Markdown>
                    {podcastUri && (
                        <a
                            href={podcastUri}
                            target="_blank"
                            rel="noreferrer"
                            className={podcastButton}
                        >
                            <Button color="green">Przejdź do podcastu</Button>
                        </a>
                    )}
                </section>
                <section className={separator}>
                    <Separator />
                </section>

                {podcastListUri && (
                    <section className={spotify}>
                        <Banner url={podcastListUri} className={spotifyBanner}>
                            <Spotify className={spotifyIcon} />
                            <Title className={titleText}>Strefa Przemian na Spotify!</Title>
                            <SubTitle className={subtitle}>
                                Sprawdź nasz najnowszy podcast.
                            </SubTitle>
                            <LinkPass name="Przejdź" />
                        </Banner>
                    </section>
                )}

                {relatedPosts.length > 0 && (
                    <RelatedList
                        emotics={FaqEmotics}
                        className={posts}
                        title="Następne artykuły"
                        subtitle="To też Cię może zainteresować, sprawdź!"
                    >
                        {relatedPosts.map((post) => (
                            <PostPreview key={post.id} post={post} />
                        ))}
                    </RelatedList>
                )}

                {relatedProducts.length > 0 && (
                    <RelatedList
                        emotics={EmoticsRelatedProd}
                        className={products}
                        title="Powiązane produkty"
                        subtitle="Tutaj znajdują się produkty, które mogą pasować do rzeczy, którą właśnie oglądasz. Stwórz swój zestaw!"
                    >
                        <List
                            listClassName={productsList}
                            itemProps={{ cartLoading }}
                            items={relatedProducts}
                            ItemComponent={ProductCard}
                            path="/sklep"
                        />
                    </RelatedList>
                )}
            </Main>
        </>
    );
};

export const query = graphql`
    query($id: String!, $relatedPostIds: [Int], $relatedProductIds: [Int]) {
        post(id: { eq: $id }) {
            ...postFields
        }
        allPost(filter: { articleId: { in: $relatedPostIds } }) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        allProduct(filter: { productId: { in: $relatedProductIds } }) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default Post;
