import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { wrapper, img } from './banner.module.scss';

const Banner = ({ url, to, image, imgStyle, className, children }) => {
    const content = () => (
        <div className={`${wrapper} ${className}`}>
            {image && (
                <GatsbyImage image={image} alt="banner" className={img} imgStyle={imgStyle} />
            )}
            {children}
        </div>
    );

    return to ? <Link to={to}>{content()}</Link> : <a href={url}>{content()}</a>;
};

export default Banner;
