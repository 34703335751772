// extracted by mini-css-extract-plugin
export var contentText = "post-module--content-text--brv1K";
export var customTitle = "post-module--custom-title--ok6cZ";
export var leadText = "post-module--lead-text---QUUT";
export var mainImg = "post-module--main-img--Gug37";
export var metadata = "post-module--metadata--vM6je";
export var podcastButton = "post-module--podcastButton--ABhBY";
export var postWrapper = "post-module--post-wrapper--X291y";
export var posts = "post-module--posts--EMGYx";
export var products = "post-module--products--zC8v4";
export var productsList = "post-module--products-list--k9nz+";
export var separator = "post-module--separator--VpHiL";
export var spotify = "post-module--spotify--X903c";
export var spotifyBanner = "post-module--spotifyBanner--H9Sc-";
export var spotifyIcon = "post-module--spotifyIcon--MQocG";
export var subtitle = "post-module--subtitle--IPr1b";
export var titleText = "post-module--title-text--TZyF1";